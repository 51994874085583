import { Box, Button, Card, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { sfLogs } from "../../../lib/api";
import { useEffect, useState } from "react";

function Logs() {
    const [isFetching, setIsFetching] = useState(false);
    const [logs, setLogs] = useState([]); // Initialize as an empty array
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    const fetchLogs = async () => {
        setIsFetching(true);
        try {
            const response = await sfLogs();
            setLogs(response.data || []); // Ensure logs is always an array
        } catch (error) {
            console.error("Error fetching logs:", error);
        } finally {
            setIsFetching(false);
        }
    };

    useEffect(() => {
        fetchLogs();
    }, []);

    // Handle pagination only if logs is not empty
    const totalPages = logs.length > 0 ? Math.ceil(logs.length / rowsPerPage) : 1;
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentData = logs.length > 0 ? logs.slice(indexOfFirstRow, indexOfLastRow) : [];

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    return (
        <>
            <Card p={5}>
                {isFetching ? (
                    <Text textAlign="center" color="gray" fontSize="xs">
                        Loading logs...
                    </Text>
                ) : logs.length > 0 ? (
                    <TableContainer>
                        <Table size="sm" variant="striped" colorScheme="orange">
                            <Thead>
                                <Tr>
                                    <Th>Timestamp</Th>
                                    <Th>Amount</Th>
                                    <Th>Comment</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {currentData.map((row) => (
                                    <Tr key={row.id}>
                                        <Td fontSize="xs">{new Date(row.timeStamp).toLocaleString()}</Td>
                                        <Td fontSize="xs" fontWeight="bold">
                                            {row.amount / 10000} KTL Credits
                                        </Td>
                                        <Td fontSize="xs">{row.comment}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Text textAlign="center" color="gray" fontSize="xs">
                        You do not have any Super Fabrications Logs yet.
                    </Text>
                )}
                {logs.length > 0 && (
                    <Flex mt={4} justifyContent="space-between" alignItems="center">
                        <Button
                            size="sm"
                            fontSize="xs"
                            onClick={handlePreviousPage}
                            isDisabled={currentPage === 1}
                        >
                            Previous
                        </Button>
                        <Box>
                            <Text fontSize="xs">
                                Page {currentPage} of {totalPages}
                            </Text>
                        </Box>
                        <Button
                            size="sm"
                            fontSize="xs"
                            onClick={handleNextPage}
                            isDisabled={currentPage === totalPages}
                        >
                            Next
                        </Button>
                    </Flex>
                )}
            </Card>
        </>
    );
}

export default Logs;
