import { Button, Card, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { superPurchase } from "../../../lib/api";
import { setUserProfile } from "../../../redux/userProfile/actions";

function Purchase() {
    const user = useSelector((state) => state.userReducer);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const toast = useToast()
    function generateArray(maxValue) {
        const array = [25];
        for (let i = 100; i <= maxValue; i += 100) {
            array.push(i);
        }
        return array;
    }
    const items = generateArray(user.kollectibles.krystel/10000)
    const maxIndex = items.length - 1;

    const [selectedIndex, setSelectedIndex] = useState(0);

    const purchaseHandler = async() => {
        await superPurchase(items[selectedIndex])
        .then((response) => {
            setIsLoading(false)
            dispatch(setUserProfile({...user, kollectibles : {
                ...user.kollectibles, krystel : user.kollectibles.krystel - items[selectedIndex]*10000
            }}))
            toast({
                title: response.data.message,
                variant: 'subtle',
                status: 'success',
                duration: 3000,
                position: 'top'
            })
        })
        .catch((error) => {
            setIsLoading(false)
            toast({
                title: error.response.data.message,
                variant: 'subtle',
                status: 'info',
                duration: 3000,
                position: 'top',
            })
        })
    }

    return (
        <>
            <Card p={5}>
                <Text fontWeight="bold" fontSize="xs">Choose an amount to start:</Text>
                <Text color="blackAlpha.700" fontWeight="bold" mt={2}>
                    Selected Amount: $ {items[selectedIndex]}
                </Text>
                <Slider
                    isDisabled={user.kollectibles.krystel / 10000 < items[0]}
                    step={1} // Each step corresponds to an index
                    min={0} // Minimum index
                    max={maxIndex} // Maximum index
                    value={selectedIndex} // Selected index
                    colorScheme="orange"
                    onChange={(val) => setSelectedIndex(val)} // Update index on change
                >
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                </Slider>
                <Flex mt={2} justifyContent="end">
                    <Button size="sm" onClick={purchaseHandler} isLoading={isLoading} isDisabled={user.kollectibles.krystel < 250000} fontSize="xs">Start Fabrication</Button>
                </Flex>
            </Card>
        </>
    );
}

export default Purchase;
