import { Button, Card, Checkbox, CheckboxGroup, Flex, Input, InputGroup, InputLeftElement, InputRightElement, Text, useToast } from "@chakra-ui/react";
import { primaryColour, primaryColourOpaced } from "../../../lib/settings";
import { useEffect, useRef, useState } from "react";
import { BiCheck, BiInfoCircle } from "react-icons/bi";
import { FaBolt } from "react-icons/fa";
import NumPad from "../../collections/misc/NumPad";
import { GiCrystalBars } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "../../../redux/userProfile/actions";
import { transferKrystel } from "../../../lib/api";

function Transfer() {
    const [wallet, setWallet] = useState('')
    const [isWalletValid, setIsWalletValid] = useState(null)
    const [amount, setAmount] = useState(10000)
    const [isAmountValid, setIsAmountValid] = useState(null)
    const user = useSelector((state) => state.userReducer);
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)
    const walletInputRef = useRef(null);
    const amountInputRef = useRef(null);
    const [isAcknowledged, setIsAcknowledged] = useState(false)
    const dispatch = useDispatch()
    function validateUsernameHash(hash) {
        if (!hash.startsWith('K')) {
            return false;
        }
        if (hash.length !== 32) {
            return false;
        }
        const randomPart = hash.slice(1);
        const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(randomPart);
    
        return isAlphanumeric;
    }
    
    const handleWalletAddresChange = (e) => {
        const el = e.target.value
        setWallet(el)
        let res = validateUsernameHash(el)
        setIsWalletValid(res)
    }


    useEffect(() => {
        if(amount >= 10000 && amount <= user.ipceWalletBalance){
            setIsAmountValid(true)
        } else{
            setIsAmountValid(false)
        }
    }, [amount])

    const handleSubmit = async () => {
        setIsLoading(true)
        if (isWalletValid) {
            if (isAmountValid) {
                await transferKrystel(wallet, amount)
                    .then((response) => {
                        setIsLoading(false)
                        dispatch(setUserProfile({
                            ...user, kollectibles: {
                                ...user.kollectibles, superOre: (user.kollectibles.superOre - amount)
                            }
                        }))
                        setWallet('')
                        setAmount(10000)
                        setIsAcknowledged(false)
                        setIsAmountValid(null)
                        setIsWalletValid(null)
                        toast({
                            title: response.data.message,
                            variant: 'subtle',
                            status: 'success',
                        })
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        toast({
                            title: error.response.data.message,
                            variant: 'subtle',
                            status: 'info',
                        })
                    })
            } else {
                setIsLoading(false)
                amountInputRef.current.focus()
                toast({
                    title: "Invalid Amount!",
                    variant: 'subtle',
                    status: 'error',
                })
            }
        } else {
            setIsLoading(false)
            walletInputRef.current.focus()
            toast({
                title: "Invalid Wallet Address!",
                variant: 'subtle',
                status: 'error',
            })
        }
    }

    return (
        <>
            <Card p={5}>
                <Flex gap={3} direction={'column'}>
                    <InputGroup px={3}>
                        <InputLeftElement pointerEvents='none'>
                        <FaBolt color={primaryColour} />
                        </InputLeftElement>
                        <Input type='text' ref={walletInputRef} color={primaryColour} value={wallet} onInput={handleWalletAddresChange}  placeholder='Krystel Wallet Address' fontSize={"sm"} fontWeight={'medium'} _placeholder={{fontSize: "sm", fontWeight: 'normal'}} variant={'flushed'} focusBorderColor={primaryColour}/>
                        <InputRightElement color={primaryColour}>
                        {isWalletValid === null ? '' : isWalletValid ? <BiCheck role="button" color="green"/> : <BiInfoCircle role="button" color="red"/> }
                        </InputRightElement>
                    </InputGroup>
                    <InputGroup px={3}>
                        <InputLeftElement pointerEvents='none'>
                        <GiCrystalBars color={primaryColour} />
                        </InputLeftElement>
                        <Input type='text' ref={amountInputRef} color={primaryColour} value={amount} readOnly placeholder='Amount' fontSize={"sm"} fontWeight={'medium'} _placeholder={{fontSize: "sm", fontWeight: 'normal'}} variant={'flushed'} focusBorderColor={primaryColour}/>
                        <InputRightElement color={primaryColour}>
                        {isAmountValid === null ? '' : isAmountValid ? <BiCheck role="button" color="green"/> : <BiInfoCircle role="button" color="red"/> }
                        </InputRightElement>
                    </InputGroup>
                    <NumPad inputValue={amount} setInputValue={setAmount}/>
                    <CheckboxGroup>
                        <Flex mt={3} gap={5} px={3}>
                            <Checkbox isChecked={isAcknowledged} onChange={() => setIsAcknowledged((prev) => !prev)} />
                            <Text textAlign={'justify'} fontSize={'9'}>I acknowledge that this transaction is irreversible, and neither Krystel nor its team is liable for any discrepancies or issues arising during the process.</Text>
                        </Flex>
                    </CheckboxGroup>
                    <Button marginTop={2} width={"100%"} isDisabled={!(isAmountValid && isWalletValid && isAcknowledged)} size={'sm'} bg={primaryColourOpaced} _hover={{backgroundColor: primaryColour}} color={"white"} onClick={() => handleSubmit()} isLoading={isLoading}>Send</Button>
                </Flex>
            </Card>            
        </>
    )
}

export default Transfer