import { Box, Button, Card, Center, Divider, Flex, Progress, Text } from "@chakra-ui/react"
import { primaryColour, primaryColourOpaced } from "../../../lib/settings"
import { PiTrophyDuotone } from "react-icons/pi";
function AllContests(){
    return(
        <>
            <Card>
                <Box p={5} pb={0}>
                    <Flex width={"100%"} direction={'column'}>
                        <Flex width={"100%"}>
                            <Text fontSize={'xs'} color={"gray.500"}>Prize Pool</Text>
                            <Text fontSize={'xs'} color={"gray.500"} ml={'auto'} mr={0}>Entry</Text>
                        </Flex>
                        <Flex width={"100%"} mt={2}>
                            <Text fontSize={'xl'} fontWeight={'bold'}>2 KTL</Text>
                            <Button my={'auto'} ml={'auto'} mr={0} size={'sm'} bg={primaryColourOpaced} _hover={{backgroundColor: primaryColour}} color={"white"} fontSize={'xs'}>500 gem6</Button>
                        </Flex>
                    </Flex>
                    <Box mt={2}>
                        <Progress value={70} size='xs' colorScheme="orange"/>
                        <Flex mt={1}>
                            <Text fontSize={'xs'} color={"gray.500"}>3 Entries left</Text>
                            <Text fontSize={'xs'} color={"gray.500"} ml={'auto'} mr={0}>10 Spots</Text>
                        </Flex>
                    </Box>
                </Box>
                <Divider color={'gray.300'} mt={4}/>
                <Box px={4} py={2} backgroundColor={'gray.100'}>
                    <Flex gap={2}>
                        <Box my={'auto'}><PiTrophyDuotone /></Box>
                        <Text my={'auto'} fontSize={'xs'}>1 KTL</Text>
                    </Flex>
                </Box>
            </Card>
        </>
    )
}

export default AllContests