import { AbsoluteCenter, Box, Button, Card, Divider, Flex, Stat, StatHelpText, StatLabel, StatNumber, Tag, Text, useToast } from "@chakra-ui/react"
import { primaryColour } from "../../../lib/settings"
import { useSelector } from "react-redux";
import { MdContentCopy } from "react-icons/md";
import { copyToClipboard, krystelValuer } from "../../../lib/support";
import { BsBoxes } from "react-icons/bs";
import { useEffect, useState } from "react";
import { fetchSuperFabs, superHarvest } from "../../../lib/api";

function Credits() {
    const user = useSelector((state) => state.userReducer);
    const toast = useToast();
    const [fabs, setFabs] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const fetchSuperFab = async (curr) => {
        setIsFetching(true)
        await fetchSuperFabs()
            .then((response) => {
                setFabs(response.data)
            })
        setIsFetching(false)
    }

    useEffect(() => {
        fetchSuperFab()
    }, [])

    const superHarvestNow = async (id) => {
        setIsLoading(true); // Set loading state before the async operation
        try {
            const response = await superHarvest(id); // Wait for the async operation to complete
            setIsLoading(false); // Reset loading state
            toast({
                title: response.data.message,
                variant: 'subtle',
                status: 'success',
                duration: 3000,
                position: 'top',
            });
            fetchSuperFab()
        } catch (error) {
            setIsLoading(false); // Reset loading state
            toast({
                title: error.response?.data?.message || "An error occurred",
                variant: 'subtle',
                status: 'info',
                duration: 3000,
                position: 'top',
            });
        }
    };
    console.log(fabs)
    return (
        <>
            <Box>
                <Card p={5}>
                    <Flex>
                        <Flex width={"85%"} direction={'column'}>
                            <Stat>
                                <StatLabel>Your available <b>Krystel Credits</b></StatLabel>
                                <StatNumber color={primaryColour}>{krystelValuer(user.ipceWalletBalance)}</StatNumber>
                                <StatHelpText color={'gray.600'}>{(user.ipceWalletBalance / 10000).toFixed(2)} KTL Credits</StatHelpText>
                            </Stat>
                            {
                                user.ipceWalletHash === '' ?
                                    <Flex mt={1}>
                                        <Tag fontSize={'xs'} color={'gray'}>Address not allocated</Tag>
                                    </Flex>
                                    :
                                    <Flex gap={1} mt={1} cursor={'pointer'} onClick={() => copyToClipboard(user.ipceWalletHash, toast, "Address")}>
                                        <Text fontSize={'xs'} color={'gray.500'}>{user.ipceWalletHash}</Text>
                                        <Text fontSize={'sm'} my={'auto'}><MdContentCopy /></Text>
                                    </Flex>
                            }
                        </Flex>
                        <Flex width={"15%"}>
                            <Text color={"gray.600"} fontSize={40} my={'auto'}><BsBoxes /></Text>
                        </Flex>
                    </Flex>
                </Card>
            </Box>
            <Box>
                <Box position='relative' my={7}>
                    <Divider />
                    <AbsoluteCenter bg='white' px='4'>
                        <Text fontSize={'xs'} color={'gray'}>Your Super Fabrications</Text>
                    </AbsoluteCenter>
                </Box>

                {
                    isFetching ?
                        <>
                            Loading
                        </> :
                        <Flex direction={'column'} gap={2} >
                            {   
                                fabs.length !== 0 ?
                                fabs.map((fab, index) => (
                                    <Card key={index} bg="#f6601d" className="shiny-card" p={5}>
                                        <Flex>
                                            <Flex width={"65%"} direction={'column'}>
                                                <Stat>
                                                    <StatLabel fontSize={'xs'} color={"white"}>Super Fabrictaion Volume</StatLabel>
                                                    <StatNumber color="white">{krystelValuer(fab.fabricatedVolume)}</StatNumber>
                                                    <StatHelpText fontSize={'xs'} color={'white'}>{(fab.fabricatedVolume / 10000).toFixed(2)} KTL Credits</StatHelpText>
                                                    <StatHelpText mt={5} fontSize={'xs'} color={'white'}>Market Vol. {krystelValuer(fab.amount)}</StatHelpText>
                                                </Stat>
                                            </Flex>
                                            <Flex width={"35%"}>
                                                <Box my={'auto'} textAlign={'end'} ml={'auto'}>
                                                    <Button size={'sm'} isLoading={isLoading} onClick={() => superHarvestNow(fab.id)} isDisabled={(fab.calculateSuperFabrication) <= 10000}>
                                                        <Text>{krystelValuer(fab.calculateSuperFabrication)}</Text>
                                                    </Button>
                                                    <Box mt={2} color={"white"} >
                                                        <Text fontSize={'xs'}>Last Harvest:</Text>
                                                        <Text fontSize={'sm'} fontWeight={'bold'}>{fab.daysFromHarvest.slice(1)} ago</Text>
                                                    </Box>

                                                </Box>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                ))
                                : <>
                                    <Text textAlign={'center'} mx={'auto'} color={'gray'} fontSize={'xs'}>You do not have any Super Fabrications yet</Text>
                                </>
                            }
                        </Flex>
                }
            </Box>
        </>
    );
}

export default Credits;
