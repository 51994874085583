import { Box, Card, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useState } from "react";
import { BiStar, BiPurchaseTagAlt } from "react-icons/bi";
import { IoChevronBackOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { maxWidthLayoutSm, primaryColourOpaced } from "../../lib/settings";
import PageLoader from "../collections/misc/PageLoader";
import Credits from "./IPCETabs/Credits";
import { GoArrowSwitch } from "react-icons/go";
import Transfer from "./IPCETabs/Transfer";
import Purchase from "./IPCETabs/Purchase";
import { IoMdList } from "react-icons/io";
import Logs from "./IPCETabs/Logs";
function IPCE(){

    const navigate = useNavigate()
    const user = useSelector((state) => state.userReducer);
    const [activeTab, setActiveTab] = useState(0)
    return (
        user.isAuthenticated ?
        <Box width={maxWidthLayoutSm} mx="auto" pt={"80px"}>
            <Card bg={primaryColourOpaced} color={"white"} padding={5} borderBottomRadius={'none'}>
                <Flex gap={2} py={2} justifyContent={"space-between"} width={"100%"}>
                    <Flex gap={2} onClick={() => navigate(-1)} role="button">
                        <IoChevronBackOutline fontWeight={'bold'} size={25} /> <Text fontWeight={"bold"} my={"auto"}>Back</Text>
                    </Flex>
                    <Text my={"auto"} fontWeight={'bold'}>Super Fabrication</Text>
                </Flex>
            </Card>
            <Card borderTopRadius={0} padding={5} marginBottom={20} height={"550px"} overflowX={"auto"}>
                <Tabs size='md' variant='soft-rounded' colorScheme="orange" defaultIndex={activeTab}>
                    <TabList gap={2}>
                        <Tab><BiStar/></Tab>
                        <Tab><GoArrowSwitch/></Tab>
                        <Tab><BiPurchaseTagAlt /></Tab>
                        <Tab><IoMdList /></Tab>
                    </TabList>
                    <TabPanels my={5}>
                        <TabPanel p={0}>
                            <Credits/>
                        </TabPanel>
                        <TabPanel p={0}>
                            <Transfer/>
                        </TabPanel>
                        <TabPanel p={0}>
                            <Purchase/>
                        </TabPanel>
                        <TabPanel p={0}>
                            <Logs/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Card>
        </Box>
        : <PageLoader/>
    )
}

export default IPCE